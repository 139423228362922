<div *ngIf="viewModel() as vm">
  <div class="modal-header">
    <div>
      <h3 class="modal-title">
        {{ vm.title }}
      </h3>
    </div>
    <button (click)="dismissModal('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body border-bottom flex-grow-1">
    <div [innerHTML]="vm.message! | safeHtml"
         class="py-1 text-lg fs-4">
    </div>
  </div>
  @if (vm.warning) {
    <div class="modal-body flex-grow-1 bg-danger-subtle fs-4 border-0">
      <span [innerHTML]="vm.warning! | safeHtml"></span>
    </div>
  }
  <div class="modal-footer">
    <button (click)="closeModal(true)"
            class="btn btn-primary px-5"
            type="button">{{ vm.confirmButtonText }}
    </button>
  </div>
</div>
