import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../modal/modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from '@twist/utils';
import { AlertModalOptions } from '../../modal/modal.service';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SafeHtmlPipe],
  templateUrl: './alert-modal.component.html'
})
export class AlertModalComponent extends ModalComponent<boolean> {
  viewModel = signal<AlertModalOptions | undefined>(undefined);
}
