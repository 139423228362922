export const Uint16MaxValue = 65535;
export const Uint8MaxValue = 255;
export const DegreesMaxValue = 360;

export function getUint8ValueFromUint16(value: number | undefined) {
  if (value) {
    return Math.round(value / (Uint16MaxValue / Uint8MaxValue));
  }
  return 0;
}

export function getUint16FromUint8Value(value: number | undefined) {
  if (value) {
    return Math.round(value * (Uint16MaxValue /Uint8MaxValue));
  } else {
    return 0;
  }
}

export function getDegreesValueFromUint16(value: number | undefined) {
  if (value) {
    return Math.round(value / (Uint16MaxValue / DegreesMaxValue));
  }
  return 0;
}

export function getUint16FromDegreesValue(value: number | undefined) {
  if (value) {
    return Math.round(value * (Uint16MaxValue /DegreesMaxValue));
  } else {
    return 0;
  }
}

export function getPercentageFromUint16(value: number | undefined) {
  if (value) {
    return Math.round(value / (Uint16MaxValue / 100));
  }
  return 0;
}

export function getUint16FromPercentage(value: number | undefined) {
  if (value) {
    return Math.round(value * (Uint16MaxValue / 100));
  } else {
    return 0;
  }
}
