import { Component, computed, HostBinding, HostListener, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-svg-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-icon.component.svg'
})
export class SvgIconComponent {
  icon = input.required<keyof { [key in SvgIcons]: string }>();/*
  iconHover = input<keyof { [key in SvgIcons]: string }>();*/
  size = input<SvgIconSize | undefined>();
  /*
    private mouseover = signal<boolean>(false);*/
  /*
    protected currentIcon = computed(() => {
      return this.mouseover() ? this.iconHover() ?? this.icon() : this.icon();
    })

    @HostListener('mouseover')
    onMouseOver() {
      this.mouseover.set(true);
    }

    @HostListener('mouseout')
    onMouseOut() {
      this.mouseover.set(false);
    }*/

  class = input<string>('');
  cssClassesSignal = computed(() => {
    const results = [this.class(), 'svg-icon'];
    if (this.size()) {
      results.push('svg-icon--size-' + this.size());
    }
    return results;
  });

  @HostBinding('attr.class')
  get cssClasses() {
    return this.cssClassesSignal().join(' ');
  }

  protected readonly SvgIcons = SvgIcons;
}

type SvgIconSize = 'small' | 'normal' | 'large' | 'xl' | 'xxl' | 'responsive'

export enum SvgIcons {
  arrowUp = 'arrowUp',
  arrowUpComplete = 'arrowUpComplete',
  arrowDown = 'arrowDown',
  arrowDownComplete = 'arrowDownComplete',
  pause = 'pause',
  onOff = 'onOff',
  reorder = 'reorder',
  drag = 'drag',
  //weather
  clearSkyDay = 'clearSkyDay',
  clearSkyNight = 'clearSkyNight',
  cloudsDay = 'cloudsDay',
  cloudsNight = 'cloudsNight',
  rain = 'rain',
  thunderStorm = 'thunderStorm',
  snow = 'snow',
  mist = 'mist',

  wind = 'wind',

  //
  check = 'check',
  alert = 'alert',
  //
  timeLapse = 'timeLapse',
  connection = 'connection',
  power = 'power',
  rssi = 'rssi',
  // Menu
  dashboard = 'dashboard',
  installations = 'installations',
  gearsDatabase = 'gearsDatabase',
  gears = 'gears',
  box = 'box',
  tag = 'tag',

  qrCode = 'qrCode',
  antenna = 'antenna',
  antennaOff = 'antennaOff',
  debug= 'debug'


}
